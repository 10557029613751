<template>
  <!--  安卓app下载-->
  <div ref="bigshow" class="box">
    <div
      @click="maskClose"
      v-if="isShow"
      style="
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 999;
        background-color: rgba(51, 51, 51, 0.6);
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          padding-top: 10px;
        "
      >
        <img style="width: 60%" src="../../assets/mask.png" />
      </div>
    </div>
    <div ref="content">
      <div ref="logo">
        <img class="logoImg" src="../../assets/XunJianGo_Logo1.png" />
      </div>
      <div ref="slogan" class="slogan">
        <p>
          <span style="color: #000000">极简</span>
          <span style="color: #d31b16">拍照</span>
          <span style="color: #000000">记录</span>
        </p>
        <p>
          <span style="color: #d31b16">一键</span>
          <span style="color: #000000">生成</span>
          <span style="color: #d31b16">巡检报告</span>
        </p>
      </div>
      <div
        v-if="openNewType === 'Android'"
        ref="download"
        class="buttons download"
        @click="AppDownLoad"
      >
        通过第三方浏览器下载
      </div>
      <div
        v-else-if="openNewType === 'IOS'"
        ref="download"
        class="buttons download"
      >
        <a
          :href="appEntity.Url"
          target="_blank"
          style="color: #fff; text-decoration: none"
          >去AppStore下载</a
        >
      </div>
      <div v-else>
        <div style="margin-bottom: 20px">
          无法判断您的系统，请手动选择下载并安装
        </div>
        <div
          ref="download"
          class="buttons download"
          style="margin-bottom: 30px"
        >
          <img
            style="width: 28px; margin-right: 10px"
            target="_blank"
            src="../../assets/Android.png"
            alt=""
          />
          <a
            :href="AndroidAppEntity.Url"
            style="color: #fff; text-decoration: none"
            >Android版下载</a
          >
        </div>
        <div ref="download" class="buttons download">
          <img
            style="width: 28px; margin-right: 10px"
            src="../../assets/IOS.png"
            alt=""
          />
          <a
            :href="IOSAppEntity.Url"
            style="color: #fff; text-decoration: none"
            target="_blank"
            >AppStore下载</a
          >
        </div>
      </div>

      <div ref="devInfo" class="devInfo">
        <p><span class="">开发者:&nbsp;&nbsp;筑赢台智慧建筑</span></p>
        <span class="">版本号:&nbsp;&nbsp;{{ VersionNumber }}</span>
      </div>
      <div ref="footer" style="text-align: center">
        <a
          href="https://www.zytsaas.com/Agreement/ZYTSaaS_FWXY.html"
          style="color: #d1100b; text-decoration: none"
          >服务协议</a
        >
        <el-divider direction="vertical"></el-divider>
        <a
          href="https://www.zytsaas.com/Agreement/ZYTSaaS_YSZC.html"
          style="color: #d1100b; text-decoration: none"
          >隐私政策</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XunJianGo",
  data() {
    return {
      isShow: false,
      appEntity: {},
      AndroidAppEntity: {},
      IOSAppEntity: {},
      href: "",
      openType: "",
      openNewType: "",
      VersionNumber: "1.1.6",
      screenWidth: "",
      screenHeight: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getOpenType();
      this.AppLoading();
    });
    this.screenWidth = document.body.clientWidth; //监听页面缩放
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  watch: {
    screenWidth() {
      this.$refs.bigshow.style.width = this.screenWidth + "px"; //浏览器窗口变化时，改变宽度。
      this.$refs.content.style.width = this.screenWidth + "px"; //设定内容区的宽度
      this.$refs.content.style.textAlign = "center";
      this.$refs.logo.style.margin = "0 auto";
      this.$refs.logo.style.padding = "50px 0 0 0";
      this.$refs.logo.style.textAlign = "center";
      this.$refs.slogan.style.width = this.screenWidth + "px";
    },
    screenHeight() {
      this.$refs.bigshow.style.height = this.screenHeight + "px"; //浏览器窗口变化时，改变高度。
      this.$refs.content.style.height = this.screenHeight * 0.7 + "px"; //设定内容区的高度
      // this.$refs.content.style.marginTop = this.screenHeight * 0.1 + "px"; //设定内容区距离顶端为4分之一的屏幕高度
      this.$refs.slogan.style.height = this.screenHeight * 0.2 + "px";
      this.$refs.slogan.style.marginTop = this.screenHeight * 0.12 + "px";
    },
  },
  created() {
    this.getOpenNewType();
  },
  methods: {
    getOpenNewType() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("android") != -1) {
        this.openNewType = "Android";
        this.getAppDownLoadURL(this.openNewType);
      } else if (ua.indexOf("iphone") != -1) {
        this.openNewType = "IOS";
        this.getAppDownLoadURL(this.openNewType);
      } else {
        this.openNewType = "Windows";
        this.getAppDownLoadURLALL();
      }

      console.log("获取getOpenNewType函数结果类型----", this.openNewType);
    },
    //获取APP下载路径
    getAppDownLoadURLALL() {
      this.$http
        .post("/ApkEdition/Apk_Edition/GetApkEdtionData?AppType=Android")
        .then((res) => {
          if (res.Success) {
            this.AndroidAppEntity = res.Data;
            this.VersionNumber = res.Data.VersionNumber;
          } else {
            this.$message.error(res.Msg);
          }
        });
      this.$http
        .post("/ApkEdition/Apk_Edition/GetApkEdtionData?AppType=IOS")
        .then((res) => {
          if (res.Success) {
            this.IOSAppEntity = res.Data;
            this.VersionNumber = res.Data.VersionNumber;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    //获取APP下载路径
    getAppDownLoadURL(type) {
      this.$http
        .post("/ApkEdition/Apk_Edition/GetApkEdtionData?AppType=" + type)
        .then((res) => {
          if (res.Success) {
            this.appEntity = res.Data;
            this.VersionNumber = res.Data.VersionNumber;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // openApp() {
    //   var ua = window.navigator.userAgent.toLowerCase();
    //   //微信
    //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //     window.location.href = this.appEntity.Url; //这个地方是下载链接
    //   } else {
    //     //非微信浏览器
    //     if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    //       window.location = this.appEntity.Url;
    //       var ifr = document.createElement("iframe");
    //       ifr.src = this.appEntity.Url;
    //       ifr.style.display = "none";
    //       document.body.appendChild(ifr);
    //       window.setTimeout(function () {
    //         document.body.removeChild(ifr);
    //         window.location.href = this.appEntity.Url; /***下载app的地址***/
    //       }, 2000);
    //     } else if (navigator.userAgent.match(/android/i)) {
    //       //var state = null;
    //       try {
    //         window.location = this.appEntity.Url;
    //         setTimeout(function () {
    //           window.location = this.appEntity.Url; //android下载地址
    //         }, 2000);
    //       } catch (e) {}
    //     }
    //   }
    // },
    // 关闭遮罩层
    maskClose() {
      this.isShow = false;
    },
    // 根据当前打开系统环境
    getOpenType() {
      const u = navigator.userAgent;
      if (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
        this.openType = "WeiXin";
      } else if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
        this.openType = "Windows";
      } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        this.openType = "MacOS";
      } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
        this.openType = "IOS";
        return;
      } else if (u.match(/android/i)) {
        this.openType = "Android";
      } else {
        this.openType = "Unknown";
      }
      console.log("获取getOpenType函数结果类型", this.openType);
    },
    //判断系统类型
    AppLoading() {
      if (this.openType === "Android") {
        this.getAppDownLoadURL("Android");
      }
      if (this.openType === "IOS") {
        this.getAppDownLoadURL("IOS");
      }
    },
    // 下载按钮点击事件
    AppDownLoad(type) {
      if (this.openNewType === "IOS") this.isShow = false;
      if (this.openType === "IOS") {
        // this.$message.error("请使用Android手机扫码!");
        // return;
      }
      if (this.openType === "WeiXin") {
        // this.$message.success("请使用浏览器打开当前页面!");
        this.isShow = true;
      }
      if (this.openType === "Windows") {
        // this.$message.success("请使用移动端浏览器打开当前页面!");
        this.isShow = true;
      }
      if (this.openType === "MacOS") {
        // this.$message.success("请使用移动端浏览器打开当前页面!");
        this.isShow = true;
      }
      if (this.openType === "Android") {
        this.downloadFile(this.appEntity.Name, this.appEntity.Url);
      }

      if (this.openType === "Unknown") {
        this.$message.success("无法识别当前打开页面系统");
      }

      console.log(
        "下载按钮点击事件类型------",
        this.openNewType,
        this.openType
      );
    },
    // 模拟超链接进行文件下载操作
    downloadFile(filename, href) {
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = href;
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-image: url(../../assets/AppBGP.webp);
  background-size: cover;
}

.logoImg {
  width: 88px;
  height: 104px;
}

.slogan {
  font-size: 26px;
  font-family: PingFangSC, serif;
  font-weight: bold;
  letter-spacing: 10px;
}

.buttons {
  border: 1px solid #d31b16;
  font-family: PingFangSC, serif;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  width: 190px;
  height: 44px;
  line-height: 44px;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  margin: 15px auto;
  border-radius: 10px;
  padding: 0 30px;
}

.download {
  color: white !important;
  background-color: #d31b16 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.openApp {
  color: #d31b16 !important;
  background-color: #ffffff !important;
}

.devInfo {
  margin: 25px auto;
  text-align: center;
  color: #a7a7a7;
  font-size: 10px;
  font-family: "PingFangSC-Regular", serif;
}
</style>
